:root {
  --page-header-bg-color: #202020;
  --spp-black: #2A363B;
  --spp-white: #FFFFFF;
  --spp-grey:  #5A6770;
  --spp-red:   #C7202F;
  --spp-dark-blue: #1A5898;
  --spp-dark-green: #427C5A;
  --spp-cornflower-blue: #A3CCFF;
  --spp-sky-blue: #BFFFF7;
  --spp-blue: #2399BB;
  --spp-green: #1FBF92;
  --dark-blue: #15467a;
  --light-blue: #8daccc;
  --dark-green: #356348;
  --light-green: #8eb09c;
  --light-grey: #F5F5F5F5;
}


#App-header {
  background-color: var(--page-header-bg-color);
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  white-space: nowrap;
}

#App-header h1 {
    font-size: xxx-large;
}

#SPP-Logo {
    margin: 20px;
}

#Page-Title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 20px;
    font-family: "Segoe UI", sans-serif;
}

#Page-Title > h1 {
    font-family: "Segoe UI", sans-serif;
}

#Page-Title > h2 {
    font-family: "Segoe UI", sans-serif;
}

#main_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    /*padding: 20px;*/
}

.main_content_sidebar {
    /*padding: 20px;*/
    /*min-height: 800px;*/
    /*min-width: 30px;*/
}

.main_content_calendar {
    flex: 1;
    padding: 20px;
    min-height: 800px;
    min-width: 800px;
}

.ui.definition.table tr td.definition, .ui.definition.table tr td:first-child:not(.ignored) {
    white-space: nowrap;
}

.App-link {
  color: #09d3ac;
}

.rbc-toolbar-label {
  text-align: center !important;
  font-weight: bold;
  font-size: xx-large !important;
  margin-bottom: 1rem;
  font-family: "Segoe UI Bold", sans-serif;
}

#copy {
    margin-left: 1rem;
}

.module {
  padding: 0px;
  margin: 0px;
  min-width: 200px;
  font-family: 'Segoe UI', sans-serif;
  z-index: 1000;
}

.module-title {
  border: 1px solid;
  border-color: white;
  border-radius: 5px 5px 0px 0px;
  margin: 0px;
  padding: 15px;
  color: white;
  font-family: "Segoe UI", sans-serif;
  /*font-weight: bold;*/
  font-size: x-large;
  text-align: center;
  background-color: var(--page-header-bg-color);
  min-width: 250px;
}

.module-content {
  border: 1px solid;
  border-color: white;
  border-radius: 0px 0px 5px 5px;
  margin: 0px;
  padding: 15px;
  min-width: 250px;
  background-color: white;
    color: black;
}

.module-header-1 {
    font-family: 'Segoe UI', sans-serif;
  font-size: medium;
  margin: 10px 0px 10px;
  display: block;
}

.module-wrap-container {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
}

.justify-contents {
  justify-content: center;
}

.change-event-legend {
  border-radius:5px;
  padding: 5px;
  color: white;
  min-width: 120px;
  max-width: 150px;
  text-align: center;
  margin: 10px;
}

.dark-blue {
  background-color: var(--spp-dark-blue) !important;
}

.light-blue {
  background-color: var(--light-blue) !important;
}

.dark-green {
  background-color: var(--spp-dark-green) !important;
}

.light-green {
  background-color: var(--light-green) !important;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    width: 100%
}

#root {
    display: flex;
    flex-direction: column;
    flex: 1;
}

body {
    min-width: 500px;
    display: flex;
    flex-direction: column;
    font-family: "Segoe UI", sans-serif;
}


#footer-spacer {
    flex: 1;
}


.About {
  height: 150px;
  background-color: var(--light-grey);
  color: var(--spp-grey);
  margin-top: 10px;
  padding: 10px;
  font-family: 'Segoe UI', sans-serif;
  font-size: medium;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Copyright {
  height: 50px;
  background-color: var(--page-header-bg-color);
  color: white;
    font-family: 'Segoe UI', sans-serif;
  font-size: medium;
  text-align: center;
  vertical-align: central;
  padding: 10px;
}